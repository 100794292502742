$(document).ready(function () {
    /** NET-939 BEGIN **/
    var promotions = $('[data-promotiongtm]');
    var promoViewGtmEvent = {
        event: 'eec.promoView',
        ecommerce: {
            promoView: {
                promotions: []
            }
        }
    };

    promotions.each(function (position, promotion) {
        var promotionGtmDataValue = $(promotion).attr('data-promotiongtm');
        if (promotionGtmDataValue) {
            var promotionGtmData = JSON.parse(promotionGtmDataValue);
            var promotionParentElement = $(promotion).parent().closest('div[data-position]');
            if (promotionParentElement.length > 0) {
                promotionGtmData.creative = 'Home Page Carousel';
                var promotionPosition = parseInt($(promotionParentElement).attr('data-position'), 10) + 1;
                promotionGtmData.position = 'Slide-' + promotionPosition;
            } else {
                promotionParentElement = $(promotion).parent().closest('div');
                promotionGtmData.creative = 'Home Page';
            }
            promoViewGtmEvent.ecommerce.promoView.promotions.push(promotionGtmData);
            $(promotionParentElement).click(function () {
                var promoClickGtmEvent = {
                    event: 'eec.promoClick',
                    ecommerce: {
                        promoClick: {
                            promotions: [
                                promotionGtmData
                            ]
                        }
                    },
                    eventCallback: function () {
                    }
                };
                // dataLayer Banner principal
                var mainBannerEvent = {
                    event: 'trackEvent',
                    eventCategory: 'main banner',
                    eventAction: 'dot click',
                    eventLabel:
                        promotionGtmData.position || $(e.target).attr('src')
                };

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push(promoClickGtmEvent, mainBannerEvent);
            });
        }
    });
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(promoViewGtmEvent);
    /** NET-939 END **/
});
